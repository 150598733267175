import axios from 'axios';
import getConfig from '../config';
import axiosAuth from '@/utils/axiosAuth';
import store from '../store/index.js';
import router from '@/router';
import i18n from '@/plugins/i18n';

const host = getConfig();

const axiosResource = axios.create({
  baseURL: `${host.resource}/api/resource`,
  withCredentials: true,
});

// Request interceptor for API calls
axiosResource.interceptors.request.use(
  async (config) => {
    const accessToken = store.getters['token'];
    const userUuid = store.getters.loggedUser.uuid;
    const projectUuid = store.getters.currentProject.uuid;
    const locale = i18n.locale;
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      'USER-UUID': userUuid,
      'PROJECT-UUID': projectUuid,
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept-Language': locale,
    };
    // config.params = { ...config.params, projectUuid: store.getters.currentProject.uuid };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosResource.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const accessToken = await refreshAccessToken();
      store.commit('setAccessToken', accessToken);
      return axiosResource(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosResource;

function refreshAccessToken() {
  const clgStyle = 'color: orange; font-size: 9px;';
  console.log('%c' + '> INTERCEPTOR: Refresh Token...', clgStyle);
  return new Promise((resolve) => {
    axiosAuth
      .post('/auth/accessToken/', {
        withCredentials: true,
      })
      .then((response) => {
        const accessToken = response.data.bearer;
        resolve(accessToken);
      })
      .catch(() => {
        router.push('/auth/login');
      });
  });
}
