const state = {
  loading: false,
};

const getters = {
  loader: (state) => {
    return state.loading;
  },
};

const mutations = {
  loading: (state, to) => {
    state.loading = to;
  },
};

export default {
  state,
  getters,
  mutations,
};
