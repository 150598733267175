const state = {
  selectedCount: 0, // Track only the count of selected items
  selectionMode: false, // TO DELETE
};

const getters = {
  selectionMode: (state) => {
    return state.selectionMode;
  },
  selectedCount: (state) => state.selectedCount,
};

const mutations = {
  setSelectionMode(state, value) {
    state.selectionMode = value;
  },
  SET_SELECTED_COUNT(state, count) {
    state.selectedCount = count; // Set the selected count
  },
  SET_SELECTION_MODE(state, mode) {
    state.selectionMode = mode;
  },
};

const actions = {
  updateSelectedCount({ commit }, count) {
    commit('SET_SELECTED_COUNT', count); // Update selected count based on provided value
  },
  updateSelectionMode({ commit }, mode) {
    commit('SET_SELECTION_MODE', mode);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
