import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import Vuelidate from 'vuelidate';
import VueTelInput from 'vue-tel-input';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './plugins/i18n';

import AedPlugin from './plugins/aed-plugin';
import Sections from './plugins/sections'; // import the plugin

import { globalSwal } from './plugins/globalSwal';

import './assets/sass/app.scss';
import 'vue-tel-input/dist/vue-tel-input.css';

import moment from 'moment';

import Masonry from 'vue-masonry-css';

import VueLazyload from 'vue-lazyload';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// or with options
const loadimage = require('./assets/gif/loadingImage.gif');
const errorimage = require('./assets/gif/error.gif');

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 3,
});

Vue.use(Masonry);

Vue.prototype.moment = moment;
Vue.prototype.$globalSwal = globalSwal;

// plugin setup
Vue.config.productionTip = false;
// Add CSP policy (again)
// const cspMetaTag = document.createElement('meta');
// cspMetaTag.setAttribute('http-equiv', 'Content-Security-Policy');
// cspMetaTag.setAttribute(
//   'content',
//   "default-src 'self'; script-src 'self' https://goso.cloud"
// );
// document.head.appendChild(cspMetaTag);
// AED Plugin
Vue.use(AedPlugin);
// Vue.prototype.$http = axios; // Axios globally (not used as could be confusing)
// Vue.prototype.$globalSwal = globalSwal; // Sweetalert
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use(VueTelInput, {
  mode: 'international',
  defaultCountry: 'FR',
  inputOptions: { placeholder: '' },
}); // Define default global options here (optional)
Vue.use(Sections);

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
});

// Directive
Vue.directive('uppercase', {
  update(el) {
    el.value = el.value.toUpperCase();
  },
});
//fix el-select
let fixElSelect = function () {
  document
    .querySelectorAll('.el-select[data-is-search="true"]:hover')
    .forEach(() => {
      let elInput = document.querySelector(
        '.el-select[data-is-search="true"]:hover input[readonly]'
      );
      if (elInput) {
        elInput.readOnly = false;
        elInput.blur();
        elInput.focus();
      }
    });
};
document.addEventListener('focusin', fixElSelect);
document.addEventListener('click', fixElSelect);
document.addEventListener('touchstart', fixElSelect);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
