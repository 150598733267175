function getConfig() {
  /*eslint-disable */
  switch (process.env.NODE_ENV) {
    case 'development':
      return {
        auth: `http://localhost:3000`,
        resource: `http://localhost:5001`,
      };
    case 'production':
      return {
        auth: `https://goso.cloud`,
        resource: `https://goso.cloud`,
      };
    // case 'production':
    //   return {
    //     auth: `https://goso.1sameorigin.com`,
    //     resource: `https://goso.1sameorigin.com`,
    //   };
  }
}
/* eslint-enable */

export default getConfig;
