import axiosResource from '@/utils/axiosResource';

const state = {
  currentStatsView: 'global',
  statusCounts: [],
  chart: {
    familyStats: [],
    general: {
      ALL: '',
      DETAILED: '',
      QUICK: '',
      CHECKLIST: '',
    },
    status: {
      OPEN: '',
      ADDRESSED: '',
      ONGOING: '',
      SOLVED: '',
      CLOSED: '',
      ONHOLD: '',
      NOACTION: '',
      DELETED: '',
    },
    statusAP: {
      ACTIVE: '',
      PASSIVE: '',
    },
    locationHint: {
      INT: '',
      EXT: '',
      CEILING: '',
      FLOOR: '',
      FACADE: '',
      ROOF: '',
      SHAFT: '',
    },
    quickInteractions: {
      LIKE_ACTIVE: '',
      LIKE_INACTIVE: '',
      VISITED_ACTIVE: '',
      VISITED_INACTIVE: '',
      LOCKED_ACTIVE: '',
      LOCKED_INACTIVE: '',
      BUSY_ACTIVE: '',
      BUSY_INACTIVE: '',
    },
    detailedInteractions: {
      INFO: '',
      LONGTERM: '',
      MEDIUMTERM: '',
      SHORTTERM: '',
      URGENT: '',
    },
  },
};

const getters = {
  statusCounts: (state) => state.statusCounts,
  currentStatsView: (state) => state.currentStatsView,
  chart: (state) => state.chart,
  familyStats: (state) => {
    return state.chart.familyStats;
  },
};

const mutations = {
  setStatusCounts(state, payload) {
    state.statusCounts = payload;
  },
  setFamilyStats(state, payload) {
    console.log('Mutation: setFamilyStats', payload); // Log mutation payload
    state.chart.familyStats = payload;
  },
  setGeneral(state, payload) {
    state.chart.general.ALL = payload.ALL;
    state.chart.general.DETAILED = payload.DETAILED;
    state.chart.general.QUICK = payload.QUICK;
    state.chart.general.CHECKLIST = payload.CHECKLIST;
  },
  setCurrentStatsView(state, view) {
    state.currentStatsView = view;
  },
  setStatus(state, payload) {
    state.chart.status.OPEN = payload.OPEN;
    state.chart.status.ADDRESSED = payload.ADDRESSED;
    state.chart.status.ONGOING = payload.ONGOING;
    state.chart.status.SOLVED = payload.SOLVED;
    state.chart.status.CLOSED = payload.CLOSED;
    state.chart.status.ONHOLD = payload.ONHOLD;
    state.chart.status.NOACTION = payload.NOACTION;
    state.chart.status.DELETED = payload.DELETED;
  },
  setStatusAP(state, payload) {
    state.chart.statusAP.ACTIVE = payload.ACTIVE;
    state.chart.statusAP.PASSIVE = payload.PASSIVE;
  },
  setLocationHint(state, payload) {
    state.chart.locationHint.INT = payload.INT;
    state.chart.locationHint.EXT = payload.EXT;
    state.chart.locationHint.CEILING = payload.CEILING;
    state.chart.locationHint.FLOOR = payload.FLOOR;
    state.chart.locationHint.FACADE = payload.FACADE;
    state.chart.locationHint.ROOF = payload.ROOF;
    state.chart.locationHint.SHAFT = payload.SHAFT;
  },
  setQuickInteractions(state, payload) {
    state.chart.quickInteractions.LIKE_ACTIVE = payload.LIKE_ACTIVE;
    state.chart.quickInteractions.LIKE_INACTIVE = payload.LIKE_INACTIVE;
    state.chart.quickInteractions.VISITED_ACTIVE = payload.VISITED_ACTIVE;
    state.chart.quickInteractions.VISITED_INACTIVE = payload.VISITED_INACTIVE;
    state.chart.quickInteractions.LOCKED_ACTIVE = payload.LOCKED_ACTIVE;
    state.chart.quickInteractions.LOCKED_INACTIVE = payload.LOCKED_INACTIVE;
    state.chart.quickInteractions.BUSY_ACTIVE = payload.BUSY_ACTIVE;
    state.chart.quickInteractions.BUSY_INACTIVE = payload.BUSY_INACTIVE;
  },
  setDetailedInteractions(state, payload) {
    state.chart.detailedInteractions.INFO = payload.INFO;
    state.chart.detailedInteractions.LONGTERM = payload.LONGTERM;
    state.chart.detailedInteractions.MEDIUMTERM = payload.MEDIUMTERM;
    state.chart.detailedInteractions.SHORTTERM = payload.SHORTTERM;
    state.chart.detailedInteractions.URGENT = payload.URGENT;
  },
};

const actions = {
  async fetchStatusCounts({ rootGetters, commit }) {
    const projectUuid = rootGetters.currentProject.uuid;
    try {
      const response = await axiosResource.get(
        `/stats/statusCount/${projectUuid}`
      );
      console.log(response.data);
      commit('setStatusCounts', response.data);
    } catch (error) {
      console.error('Error fetching status counts:', error);
    }
  },
  async fetchStats({ rootGetters, dispatch }, chart) {
    const clgStyle = 'color: red;font-size:24px;';
    console.log('%c' + 'Fetch for ' + chart, clgStyle);
    console.log(rootGetters.currentStatsView);
    const data = {
      buildingName: rootGetters.building.label,
      level: rootGetters.level,
      zone: rootGetters.zone,
      serial: rootGetters.serial,
      definition: rootGetters.definition.label,
      location: rootGetters.location,
      priority: rootGetters.priority,
      quick: rootGetters.quick,
      status: rootGetters.status,
      program: rootGetters.program,
      sorting: rootGetters.sorting,
      freeTag: rootGetters.freeTag,
      dateStart: rootGetters.dateStart,
      dateEnd: rootGetters.dateEnd,
      entity: rootGetters.entity,
      inspector: rootGetters.inspector,
      nature: rootGetters.nature,
      interventionType: rootGetters.interventionType,
      exactMatch: rootGetters.exactMatch,
      withWI: rootGetters.withWI,
      withoutWI: rootGetters.withoutWI,
    };
    if (rootGetters.currentStatsView == 'global') {
      return await dispatch(`FETCH_${chart}`, data);
    }
    if (rootGetters.currentStatsView == 'program')
      console.log('%c' + '... for fetchCategoriesStats' + chart, clgStyle);
    dispatch('fetchFamilyStats', data);

    if (rootGetters.currentStatsView == 'curve') dispatch('fetchProgressStats');
  },
  async FETCH_general({ commit, rootGetters }, data) {
    const clgStyle = 'color: blue;';
    console.log('%c' + 'FETCH_general', clgStyle);
    console.log(data);
    // PARAMS VARIABLES
    const projectRef = rootGetters.currentProject.ref;
    const projectUuid = rootGetters.currentProject.uuid;
    // AXIOS REQUEST
    return await axiosResource
      .post(`/stats/general/${projectUuid}/${projectRef}`, {
        data,
      })
      .then((response) => {
        const clgStyle = 'color: green;font-weight:900;';
        console.log('%c' + 'RESPONSE AXIOS: ' + 'general', clgStyle);
        console.log(response);
        const payload = {
          ALL: response.data.ALL,
          DETAILED: response.data.DETAILED,
          QUICK: response.data.QUICK,
          CHECKLIST: response.data.CHECKLIST,
        };
        commit('setGeneral', payload);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async FETCH_status({ commit, rootGetters, state }, data) {
    const clgStyle = 'color: blue;';
    console.log('%c' + 'FETCH_status', clgStyle);
    console.log(data);
    // PARAMS VARIABLES
    const projectRef = rootGetters.currentProject.ref;
    const projectUuid = rootGetters.currentProject.uuid;
    // AXIOS REQUEST
    return await axiosResource
      .post(`/stats/status/${projectUuid}/${projectRef}`, {
        data,
      })
      .then((response) => {
        const clgStyle = 'color: green;font-weight:900;';
        console.log('%c' + 'RESPONSE AXIOS: ' + 'status', clgStyle);
        console.log(response);
        const payload = {
          OPEN: response.data.OPEN,
          ADDRESSED: response.data.ADDRESSED,
          ONGOING: response.data.ONGOING,
          SOLVED: response.data.SOLVED,
          CLOSED: response.data.CLOSED,
          ONHOLD: response.data.ONHOLD,
          NOACTION: response.data.NOACTION,
          DELETED: response.data.DELETED,
        };
        commit('setStatus', payload);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async FETCH_statusAP({ commit, rootGetters }, data) {
    const clgStyle = 'color: blue;';
    console.log('%c' + 'FETCH_statusAP', clgStyle);
    console.log(data);
    // PARAMS VARIABLES
    const projectRef = rootGetters.currentProject.ref;
    const projectUuid = rootGetters.currentProject.uuid;
    // AXIOS REQUEST
    return await axiosResource
      .post(`/stats/statusAP/${projectUuid}/${projectRef}`, {
        data,
      })
      .then((response) => {
        const clgStyle = 'color: green;font-weight:900;';
        console.log('%c' + 'RESPONSE AXIOS: ' + 'statusAP', clgStyle);
        console.log(response);
        const payload = {
          ACTIVE: response.data.ACTIVE,
          PASSIVE: response.data.PASSIVE,
        };
        commit('setStatusAP', payload);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async FETCH_quickInteractions({ commit, rootGetters, state }, data) {
    const clgStyle = 'color: blue;';
    console.log('%c' + 'FETCH_quickInteractions', clgStyle);
    console.log(data);
    // PARAMS VARIABLES
    const projectRef = rootGetters.currentProject.ref;
    const projectUuid = rootGetters.currentProject.uuid;
    // AXIOS REQUEST
    return await axiosResource
      .post(`/stats/quickInteractions/${projectUuid}/${projectRef}`, {
        data,
      })
      .then((response) => {
        const clgStyle = 'color: green;font-weight:900;';
        console.log('%c' + 'RESPONSE AXIOS: ' + 'quickInteractions', clgStyle);
        console.log(response);
        const payload = {
          LIKE_ACTIVE: response.data.LIKE_ACTIVE,
          LIKE_INACTIVE: response.data.LIKE_INACTIVE,
          VISITED_ACTIVE: response.data.VISITED_ACTIVE,
          VISITED_INACTIVE: response.data.VISITED_INACTIVE,
          LOCKED_ACTIVE: response.data.LOCKED_ACTIVE,
          LOCKED_INACTIVE: response.data.LOCKED_INACTIVE,
          BUSY_ACTIVE: response.data.BUSY_ACTIVE,
          BUSY_INACTIVE: response.data.BUSY_INACTIVE,
        };
        commit('setQuickInteractions', payload);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async FETCH_detailedInteractions({ commit, rootGetters, state }, data) {
    const clgStyle = 'color: blue;';
    console.log('%c' + 'FETCH_detailedInteractions', clgStyle);
    console.log(data);
    // PARAMS VARIABLES
    const projectRef = rootGetters.currentProject.ref;
    const projectUuid = rootGetters.currentProject.uuid;
    // AXIOS REQUEST
    return await axiosResource
      .post(`/stats/detailedInteractions/${projectUuid}/${projectRef}`, {
        data,
      })
      .then((response) => {
        const clgStyle = 'color: green;font-weight:900;';
        console.log(
          '%c' + 'RESPONSE AXIOS: ' + 'detailedInteractions',
          clgStyle
        );
        console.log(response);
        const payload = {
          INFO: response.data.INFO,
          LONGTERM: response.data.LONGTERM,
          MEDIUMTERM: response.data.MEDIUMTERM,
          SHORTTERM: response.data.SHORTTERM,
          URGENT: response.data.URGENT,
        };
        commit('setDetailedInteractions', payload);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async FETCH_locationHint({ commit, rootGetters }, data) {
    const clgStyle = 'color: blue;';
    console.log('%c' + 'FETCH_locationHint', clgStyle);
    console.log(data);
    // PARAMS VARIABLES
    const projectRef = rootGetters.currentProject.ref;
    const projectUuid = rootGetters.currentProject.uuid;
    // AXIOS REQUEST
    return await axiosResource
      .post(`/stats/locationHint/${projectUuid}/${projectRef}`, {
        data,
      })
      .then((response) => {
        const clgStyle = 'color: green;font-weight:900;';
        console.log('%c' + 'RESPONSE AXIOS: ' + 'locationHint', clgStyle);
        console.log(response);
        const payload = {
          INT: response.data.INT,
          EXT: response.data.EXT,
          CEILING: response.data.CEILING,
          FLOOR: response.data.FLOOR,
          FACADE: response.data.FACADE,
          ROOF: response.data.ROOF,
          SHAFT: response.data.SHAFT,
        };
        commit('setLocationHint', payload);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async fetchFamilyStats({ commit, rootGetters }, data) {
    const clgStyle = 'color: blue;font-size: 16px;';
    console.log('%c' + 'Action: fetchFamilyStats', clgStyle);
    const projectUuid = rootGetters.currentProject.uuid;
    console.log('data', data);
    try {
      const response = await axiosResource.post(
        `/stats/familyStats/${projectUuid}/`,
        {
          data,
        }
      );
      console.log('API Response:', response.data); // Log API response
      const familyData = response.data.map((item) => ({
        familyName: item.familyName,
        count: item.count,
        color: item.color || '#000000', // Use color from database, default to black if not available
      }));
      const clgStyle = 'color: orange;';
      console.log('%c' + 'familyData', clgStyle);
      console.log(familyData);
      commit('setFamilyStats', familyData);
    } catch (error) {
      console.error('Error fetching family stats:', error);
    }
  },
  async fetchCategoriesStats({ commit, rootGetters }, data) {
    console.log('fetchCategoriesStats (STORE)');
    const clgStyle = 'color: blue;';
    console.log('%c' + 'fetchProgramStats', clgStyle);
    console.log(data);
    // PARAMS VARIABLES
    const projectRef = rootGetters.currentProject.ref;
    const projectUuid = rootGetters.currentProject.uuid;
    // AXIOS REQUEST
    return await axiosResource
      .post(`/stats/familyStats/${projectUuid}`, {
        data,
      })
      .then((response) => {
        const clgStyle = 'color: green;font-weight:900;';
        console.log('%c' + 'RESPONSE AXIOS: ' + 'familyStats', clgStyle);
        console.log(response);
        commit('setFamilyStats', response.data);
        commit('updateChartData'); // Add this commit to trigger chart update
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async fetchProgressStats({ commit, rootGetters }) {
    console.log('fetchProgressStats');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
