const state = {
  lastSubsectionRoute: '',
  lastSubsectionRouteInSection: {
    admin: '/admin/entity/entitylist',
    settings: '/settings/category/categoryconfig',
    search: '/search/deficiency/filterdeficiency',
    deficiency: '',
    notifications: '/notifications/notification/notificationlist',
    stats: '/stats',
    bhd: '/bhd/handle/bhdRegister',
    home: '/home/home/homePage',
    explore: '/explore',
  },
};

const getters = {
  section: (state) => {
    return state.section;
  },
  lastSubsectionRoute: (state) => {
    return state.lastSubsectionRoute;
  },
  lastSubsectionRouteInSection: (state) => {
    return state.lastSubsectionRouteInSection;
  },
};

const mutations = {
  SAVE_CONTEXT(state, route) {
    // save lastSubsectionRoute (used when login in)
    state.lastSubsectionRoute = route;
    // get section from subsection route (when navigationg through sections)
    const pathArray = route.split('/');
    const section = pathArray[1];
    state.section = section;
    // save lastSubsectionRouteInSection
    state.lastSubsectionRouteInSection[section] = route;
  },
  CLEAR_lastSubsectionRoute(state) {
    state.lastSubsectionRoute = '';
  },
};

export default {
  state,
  getters,
  mutations,
};
