import axiosResource from '@/utils/axiosResource';

const state = {
  isFetching: false,
  reports: [],
  reportUuid: '',
  reportSequenceNumber: '',
  reportRef: '',
  selectedUuids: [],
  selectedReports: [],
  selectedReportsData: [],
  allSelected: false,
  tableLoading: true,
  qtt: 0,
  qttLoaded: 0,
  page: 0,
  limit: 40,
  wis: {},
};

const getters = {
  isFetching: (state) => state.isFetching,
  allSelected(state) {
    return state.allSelected;
  },
  reports: (state) => {
    return state.reports;
  },
  reportUuid: (state) => {
    return state.reportUuid;
  },
  reportSequenceNumber: (state) => {
    return state.reportSequenceNumber;
  },
  reportRef: (state) => {
    return state.reportRef;
  },
  selectedUuids: (state) => {
    return state.selectedUuids;
  },
  selectedReports: (state) => {
    return state.selectedReports;
  },
  selectedReportsData: (state) => {
    return state.selectedReportsData;
  },
  allUuids: (state) => {
    return state.allUuids;
  },
  tableLoading: (state) => {
    return state.tableLoading;
  },
  qtt: (state) => {
    return state.qtt;
  },
  qttLoaded: (state) => {
    return state.qttLoaded;
  },
  page: (state) => {
    return state.page;
  },
  limit: (state) => {
    return state.limit;
  },
  getWI: (state) => (reportUuid) => state.wis[reportUuid] || {},
};

const mutations = {
  SET_FETCHING(state, value) {
    state.isFetching = value; // Update fetching status
  },
  UPDATE_REPORTS(state, updatedReports) {
    state.reports = updatedReports;
  },
  toggleLoader: (state) => {
    state.tableLoading = !state.tableLoading;
  },
  setReports: (state, reports) => {
    // Merge the second array into the first one
    state.reports.push(...reports);
    state.tableLoading = false;
  },
  setReportUuid: (state, uuid) => {
    state.reportUuid = uuid;
  },
  clearReportUuid(state) {
    state.reportUuid = null;
  },
  setReportRef: (state, reportRef) => {
    state.reportRef = reportRef;
  },
  setAllUuids: (state, allUuids) => {
    state.allUuids = allUuids;
  },
  setSelection: (state, selection) => {
    state.selectedReports = selection;
  },
  addToSelection: (state, selection) => {
    selection.forEach((uuid) => {
      if (!state.selectedReports.includes(uuid)) {
        state.selectedReports.push(uuid);
      }
    });
  },
  deleteSelection: (state) => {
    state.selectedReports = []; // This should also be reactive
  },
  setAllSelected(state, allSelected) {
    // Mutation for allSelected
    state.allSelected = allSelected;
  },
  // addToSelection: (state, selection) => {
  //   selection.forEach((uuid) => {
  //     // state.selectedReports.push(uuid);
  //     if (!state.selectedReports.includes(uuid)) {
  //       state.selectedReports.push(uuid);
  //     }
  //   });
  // },
  // selectReport_OBS: (state, reportUuid) => {
  //   state.selectedReports.push(reportUuid);
  // },
  // selectReportData_OBS: (state, report) => {
  //   state.selectedReportsData.push(report);
  // },
  // unselectReport_OBS: (state, reportUuid) => {
  //   state.selectedReports = state.selectedReports.filter(function (uuid) {
  //     return uuid !== reportUuid;
  //   });
  // },
  // unselectReportData_OBS: (state, reportUuid) => {
  //   // state.selectedReportsData = [];
  //   state.selectedReportsData = state.selectedReportsData.filter(function (
  //     report
  //   ) {
  //     return report.uuid !== reportUuid;
  //   });
  // },
  selectReport(state, reportUuid) {
    if (!state.selectedReports.includes(reportUuid)) {
      state.selectedReports.push(reportUuid);
    }
  },
  selectReportData(state, report) {
    if (!state.selectedReportsData.find((r) => r.uuid === report.uuid)) {
      state.selectedReportsData.push(report);
    }
  },
  unselectReport(state, reportUuid) {
    state.selectedReports = state.selectedReports.filter(
      (uuid) => uuid !== reportUuid
    );
  },
  unselectReportData(state, reportUuid) {
    state.selectedReportsData = state.selectedReportsData.filter(
      (report) => report.uuid !== reportUuid
    );
  },
  deleteSelection: (state) => {
    state.selectedReports = [];
    state.selectedReportsData = [];
  },
  setQtt: (state, qtt) => {
    state.qtt = qtt;
  },
  incrementQttLoaded: (state, value) => {
    state.qttLoaded += value;
  },
  resetQttLoaded: (state) => {
    state.qttLoaded = 0;
  },
  initPage: (state) => {
    state.page = 0;
  },
  initReports: (state) => {
    state.reports = [];
  },
  incrementPage: (state) => {
    state.page++;
  },
  SET_WI(state, { reportUuid, wi }) {
    // Update wis object
    state.wis = { ...state.wis, [reportUuid]: wi };

    // Update the specific report in the reports array
    const reportIndex = state.reports.findIndex(
      (report) => report.uuid === reportUuid
    );
    if (reportIndex !== -1) {
      state.reports[reportIndex] = {
        ...state.reports[reportIndex],
        wiRef: wi.wiRef,
        wiDueDate: wi.wiDueDate,
      };
    }
  },
};

const actions = {
  updateWIForReports({ commit }, { wiRef, wiDueDate, reportUuids }) {
    // For each report UUID, commit the updated WI information
    reportUuids.forEach((reportUuid) => {
      commit('SET_WI', {
        reportUuid,
        wi: { wiRef, wiDueDate },
      });
    });
  },
  changeStatus({ rootGetters, commit }, { status, reportUuids }) {
    rootGetters.reports.forEach((report) => {
      if (reportUuids.includes(report.uuid)) {
        report.status = status;
      }
    });

    // Optionally, commit a mutation to trigger any necessary reactivity
    commit('UPDATE_REPORTS', rootGetters.reports);
  },
  setSelection({ commit }, uuids) {
    commit('setSelection', uuids);
  },
  async fetchReportsGeneric_OLD(
    { commit, rootGetters },
    fetchFromSelection = false
  ) {
    commit('loading', true);

    if (rootGetters.isFetching) {
      console.warn('Fetch already in progress.');
      return;
    }

    commit('SET_FETCHING', true);

    // VARIABLES
    const projectUuid = rootGetters.currentProject.uuid;
    const projectRef = rootGetters.currentProject.ref;
    const offset = rootGetters.page * rootGetters.limit;
    const limit = rootGetters.limit;

    let response; // Declare response outside the try block

    try {
      // AXIOS REQUEST
      const endpoint = fetchFromSelection
        ? `/reports/fetchFromSelection/${projectUuid}`
        : `/reports/fetch/${projectUuid}`;

      const requestData = fetchFromSelection
        ? {
            userUuid: rootGetters.userUuid,
            projectUuid,
            data: {
              reactiveSorting: rootGetters.getSortingPreference,
            },
          }
        : {
            data: {
              projectRef,
              reportSequenceNumber: rootGetters.reportSequenceNumber,
              reportRef: rootGetters.reportRef,
              buildingName: rootGetters.building.label,
              level: rootGetters.level,
              zone: rootGetters.zone,
              serial: rootGetters.serial,
              definition: rootGetters.definition.label,
              location: rootGetters.location,
              priority: rootGetters.priority,
              quick: rootGetters.quick,
              status: rootGetters.status,
              program: rootGetters.program,
              exactMatch: rootGetters.exactMatch,
              sorting: rootGetters.sorting,
              freeTag: rootGetters.freeTag,
              dateStart: rootGetters.dateStart,
              dateEnd: rootGetters.dateEnd,
              entity: rootGetters.entity,
              inspector: rootGetters.inspector,
              nature: rootGetters.nature,
              interventionType: rootGetters.interventionType,
              withWI: rootGetters.withWI,
              reactiveSorting: rootGetters.getSortingPreference,
            },
          };

      response = await axiosResource.post(
        `${endpoint}?limit=${limit}&offset=${offset}`,
        requestData
      );

      // Set selection mode for fetchFromSelection
      if (fetchFromSelection) {
        commit('setSelectionMode', true);
      }

      // Feed store with commentsQtt and WI
      response.data.result.forEach((report) => {
        commit('setCommentCount', {
          reportUuid: report.uuid,
          count: report.commentsQtt,
        });
        commit('SET_WI', {
          reportUuid: report.uuid,
          wi: {
            wiRef: report.wiRef,
            wiDueDate: report.wiDueDate,
            wiPriority: report.wiPriority,
          },
        });
      });

      // COMMITS TO POPULATE STORE
      commit('setAllSelected', response.data.allSelected);
      commit('setQtt', response.data.total);
      commit('setReports', response.data.result);
      commit('setAllUuids', response.data.allUuids);

      // if (!fetchFromSelection) {
      commit('toggleLoader');
      // }

      const qtt = rootGetters.qtt;
      const qttLoaded = rootGetters.qttLoaded;
      // console.warn(`${qttLoaded} / ${qtt}`);
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      commit('loading', false);
      commit('SET_FETCHING', false);

      if (response && response.data && response.data.result) {
        commit('incrementQttLoaded', response.data.result.length);
        const qtt = rootGetters.qtt;
        const qttLoaded = rootGetters.qttLoaded;
        // console.warn(`${qttLoaded} / ${qtt}`);
      } else {
        console.warn(
          'Response is undefined or invalid; skipping incrementQttLoaded.'
        );
      }
    }
  },
  async fetchReportsGeneric(
    { dispatch, commit, rootGetters },
    fetchFromSelection = false
  ) {
    commit('loading', true);

    if (rootGetters.isFetching) {
      console.warn('Fetch already in progress.');
      return;
    }

    commit('SET_FETCHING', true);

    const projectUuid = rootGetters.currentProject.uuid;
    const offset = rootGetters.page * rootGetters.limit;
    const limit = rootGetters.limit;

    const endpoint = fetchFromSelection
      ? `/reports/fetchFromSelection/${projectUuid}`
      : `/reports/fetch/reports/${projectUuid}`;

    const requestData = {
      data: {
        projectRef: rootGetters.currentProject.ref,
        buildingName: rootGetters.building.label,
        level: rootGetters.level,
        zone: rootGetters.zone,
        serial: rootGetters.serial,
        definition: rootGetters.definition.label,
        location: rootGetters.location,
        priority: rootGetters.priority,
        quick: rootGetters.quick,
        status: rootGetters.status,
        program: rootGetters.program,
        exactMatch: rootGetters.exactMatch,
        sorting: rootGetters.sorting,
        freeTag: rootGetters.freeTag,
        dateStart: rootGetters.dateStart,
        dateEnd: rootGetters.dateEnd,
        entity: rootGetters.entity,
        inspector: rootGetters.inspector,
        nature: rootGetters.nature,
        interventionType: rootGetters.interventionType,
        withWI: rootGetters.withWI,
        withoutWI: rootGetters.withoutWI,
        reactiveSorting: rootGetters.getSortingPreference,
      },
    };

    try {
      // Fetch total count in parallel
      if (!fetchFromSelection) {
        dispatch('fetchTotal');
      }

      // Fetch paginated reports
      const response = await axiosResource.post(
        `${endpoint}?limit=${limit}&offset=${offset}`,
        requestData
      );
      const reports = response.data.result;

      // Set selection mode for fetchFromSelection
      if (fetchFromSelection) {
        commit('setSelectionMode', true);
      }

      // Feed store with commentsQtt and WI
      response.data.result.forEach((report) => {
        commit('setCommentCount', {
          reportUuid: report.uuid,
          count: report.commentsQtt,
        });
        commit('SET_WI', {
          reportUuid: report.uuid,
          wi: {
            wiRef: report.wiRef,
            wiDueDate: report.wiDueDate,
            wiPriority: report.wiPriority,
          },
        });
      });

      // Commit reports to store
      commit('setAllSelected', response.data.allSelected);
      commit('setReports', reports);
      commit('incrementQttLoaded', reports.length); // Update the count of loaded reports
      commit('setAllUuids', response.data.allUuids);
      // commit('toggleLoader');

      if (fetchFromSelection) {
        console.log('total from delection', response.data.total);
        commit('setQtt', response.data.total); // Commit the total to Vuex state
      }
      console.log(`Loaded ${reports.length} reports`);
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      commit('loading', false);
      commit('SET_FETCHING', false);
    }
  },
  async fetchTotal({ commit, rootGetters }) {
    commit('setQtt', '0');

    const endpoint = `/reports/fetch/total`;
    const requestData = {
      data: {
        projectRef: rootGetters.currentProject.ref,
        buildingName: rootGetters.building.label,
        level: rootGetters.level,
        zone: rootGetters.zone,
        serial: rootGetters.serial,
        definition: rootGetters.definition.label,
        location: rootGetters.location,
        priority: rootGetters.priority,
        quick: rootGetters.quick,
        status: rootGetters.status,
        program: rootGetters.program,
        exactMatch: rootGetters.exactMatch,
        freeTag: rootGetters.freeTag,
        dateStart: rootGetters.dateStart,
        dateEnd: rootGetters.dateEnd,
        entity: rootGetters.entity,
        inspector: rootGetters.inspector,
        nature: rootGetters.nature,
        interventionType: rootGetters.interventionType,
        withWI: rootGetters.withWI,
        withoutWI: rootGetters.withoutWI,
        reactiveSorting: rootGetters.getSortingPreference,
      },
    };

    try {
      const response = await axiosResource.post(endpoint, requestData);

      const total = response.data.total;
      commit('setQtt', total); // Commit the total to Vuex state
    } catch (error) {
      console.error('Error fetching total:', error);
    }
  },
  setWI({ commit }, payload) {
    commit('SET_WI', payload);
  },
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
