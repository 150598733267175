// store/modules/sorting.js

const state = {
  sortingPreference: 'latestReports', // Default sorting
};

const mutations = {
  SET_SORTING_PREFERENCE(state, preference) {
    state.sortingPreference = preference;
  },
};

const actions = {
  setSortingPreference({ commit }, preference) {
    commit('SET_SORTING_PREFERENCE', preference);
  },
};

const getters = {
  getSortingPreference(state) {
    return state.sortingPreference;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
